import { type LinksFunction, type LoaderFunction, type MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { SpartaProvider, Toaster } from "sparta";

import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import navigationBarStyle from "~/style/components/NavigationBar.css?url";
import global from "~/style/global.css?url";

import favicon from "~/assets/favicon.ico";
import exportBarStyle from "~/style/components/ExportBar.css?url";
import exportButtonStyle from "~/style/components/ExportButton.css?url";
import linkButtonStyle from "~/style/components/LinkButton.css?url";
import postModuleStyle from "~/style/components/PostModule.css?url";
import webBlockStyle from "~/style/components/WebBlock.css?url";
import { g2xTheme } from "./assets/g2x.theme";

import { ApolloProvider } from "@apollo/client/index.js";
import { useEffect, useRef } from "react";
import Footer from "./components/root/Footer";
import ServerError from "./components/root/ServerError";
import { getEnv } from "./env.server";
import { apolloClient } from "./utils/graphql/apollo-client";

import posthog from "posthog-js";
import paginationStyle from "~/style/components/Pagination.css?url";
import StateBanner, { stateBannerStyle } from "./components/layout/StateBanner";
import { AppLayout } from "./components/root/AppLayout/AppLayout";
import { Search } from "./components/search/Search";
import { FingerprintProvider } from "./context/FingerprintContext";
import { PlatformClass } from "./sdk/qs1/Platform";
// @ts-ignore
import { Post } from "./sdk/qs1/index.server";
import type { Platform, Subscription, User } from "./sdk/qs1/index.server";
import { withSession } from "./sdk/qs1/wrappers";
import { checkFlag } from "./utils/featureFlags";
import gtm, { analytics } from "./utils/gtm";
import useCatchLog from "./utils/hooks/useCatchLog";
import { setupIntercom } from "./utils/intercom";
import { getSessionToken } from "./utils/session.server";

export const links: LinksFunction = () => {
  return [
    { rel: "icon", href: favicon },
    { rel: "stylesheet", href: global },
    { rel: "stylesheet", href: navigationBarStyle },
    { rel: "stylesheet", href: webBlockStyle },
    { rel: "stylesheet", href: linkButtonStyle },
    { rel: "stylesheet", href: postModuleStyle },
    { rel: "stylesheet", href: exportBarStyle },
    { rel: "stylesheet", href: exportButtonStyle },
    { rel: "stylesheet", href: stateBannerStyle },
    { rel: "stylesheet", href: paginationStyle },
  ];
};

export const meta: MetaFunction = ({ params }) => {
  let platform = params?.platform;
  // display platform name in title
  platform = PlatformClass.toPlatform(platform, "");

  return [
    { charSet: "utf-8" },
    { title: `G2X ${platform}` },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1",
    }, // Updated here
    { name: "theme-color", content: "#0e214b" },
  ];
};

interface Context {
  user?: User | null;
  agencies?: string[];
  ENV: any;
}

interface LoaderData {
  agencies: string[];
  user: User | null;
  deployEnv: string;
  ENV: any;
  path: string;
  categories: string[];
  subscription: Subscription | null;
  userIsActive: boolean;
  platform: Platform | null;
  activePlatforms: Platform[];
  isloggedin: boolean;
  token: string;
  showMembers: boolean;
  showBilling: boolean;
  showStripe: boolean;
  canSeeLumen: boolean;
  canSeeOpportunities: boolean;
}
export const loader: LoaderFunction = withSession(async ({ request }, session): Promise<Context> => {
  const url = new URL(request.url);
  const deployEnv = url.hostname.split(".")?.[0] ?? "localhost";
  const platform = session.detectPlatform();
  const canUseMaintenance = await checkFlag("maintenance-page");
  const canSeeLumen = await checkFlag("visible-lumen");
  const canSeeOpportunities = await checkFlag("visible-opportunities");

  // Redirect to the maintenance page if the canUseMaintenance flag is true
  if (canUseMaintenance && url.pathname !== "/maintenance") {
    throw new Response(null, {
      status: 302, // HTTP status for a temporary redirect
      headers: {
        Location: "/maintenance",
      },
    });
  }

  // check feature flag in categories
  const categories = await Post.getCategories();
  const agencies: string[] = [];

  // get data
  const loaderData: LoaderData = {
    agencies,
    deployEnv,
    path: url.pathname,
    user: null,
    userIsActive: false,
    subscription: null,
    ENV: getEnv(),
    categories,
    platform,
    activePlatforms: [],
    isloggedin: false,
    token: null,
    showMembers: false,
    showBilling: false,
    showStripe: false,
    canSeeLumen,
    canSeeOpportunities,
  };

  // get post
  if (await session.check()) {
    const user = await session.validate();
    // await user.syncPersonalData();
    loaderData.user = user;
    loaderData.activePlatforms = user.activePlatforms();

    if (platform) {
      loaderData.subscription = user.getSubscription(platform);
    }

    loaderData.userIsActive = user.isActive();
    loaderData.isloggedin = !user.anonymous;
    loaderData.showMembers = user.is("account_owner") && (user.hasPlan("Business") || user.hasPlan("Enterprise"));
    loaderData.showBilling = user.is("account_owner");
    loaderData.showStripe = user.is("billing_manager");
    loaderData.canSeeOpportunities = await user.canUse("visible-opportunities");
  }

  loaderData.token = await getSessionToken(request);
  return loaderData;
});

export function useRootLoaderData() {
  return useRouteLoaderData<LoaderData>("root");
}

function App() {
  const location = useLocation();
  const scrollPositions = useRef<Map<string, number>>(new Map());

  useEffect(() => {
    const handleScroll = () => {
      scrollPositions.current.set(location.key, window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.key]);

  useEffect(() => {
    const savedScrollPosition = scrollPositions.current.get(location.key);
    if (savedScrollPosition !== undefined) {
      window.scrollTo(0, savedScrollPosition);
    }
  }, [location]);

  // feeding argument values to initialize tag manager
  useEffect(() => {
    analytics(
      window,
      document,
      "script",
      "dataLayer",
      "GTM-K839NR2",
      user?.data?.id,
      user?.data?.fullName,
      user?.data?.email,
      user?.data?.paymentaccounts?.[0]?.companyAccount?.companyName,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // get loader data
  const { user, ENV: loadedEnv, deployEnv } = useLoaderData<LoaderData>() as LoaderData;

  // Initialize analytics
  useEffect(() => {
    posthog.init(loadedEnv.POSTHOG_PUBLIC_KEY, {
      mask_all_text: false, // This disables masking all text by default (Except Passwords)
      mask_all_element_attributes: false, // This disables masking select/option elements

      capture_pageview: true,
      loaded: (posthog) => {
        // Unmask email fields
        const unmaskEmailFields = () => {
          const emailFields = document.querySelectorAll('input[type="email"]');
          for (const field of emailFields) {
            field.addEventListener("blur", () => {
              field.setAttribute("data-ph-capture", "true");
            });
          }
        };

        // Initial unmasking
        unmaskEmailFields();

        // Unmask email fields whenever the DOM changes
        const observer = new MutationObserver(unmaskEmailFields);
        observer.observe(document.body, { childList: true, subtree: true });

        // Identify the user if you have user details available (this will help us instead of showing long user ids in the dashboard)
        if (user?.id) {
          posthog.identify(user.id, {
            email: user.data?.email,
            name: user.data?.fullName,
          });
        }
      },
    });

    const gtmScript = document.getElementById("gtm");
    if (gtmScript) {
      gtmScript.innerHTML = gtm;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Capture pageviews
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  // Identify user
  useEffect(() => {
    if (user?.id) {
      posthog.identify(user.id);
    }
  }, [user]);

  useEffect(() => {
    // Once user data is available, we setup Intercom
    if (user) {
      setupIntercom({
        name: user.data?.fullName, // Adjusting according to actual user data structure
        user_id: user.id,
        email: user.data?.email,
        createdAt: user.data?.createdAt, // we were not fetching createdAt before, started fetching now.
      });
    } else {
      setupIntercom({
        user_id: user?.id, // for annonymous users (required by intercom)
      });
    }
  }, [user]);

  return (
    <html lang="en">
      <head>
        <script id="gtm" />
        <Meta />
        <Links />
      </head>
      <body>
        <noscript>
          <iframe
            title="gtm"
            src="https://www.googletagmanager.com/ns.html?id=GTM-K839NR2"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
        <span aria-hidden data-message="Made with love - a team of badass devs ❤️" />
        <ApolloProvider client={apolloClient}>
          <FingerprintProvider>
            <SpartaProvider>
              <StateBanner />
              <Search>
                <AppLayout>
                  <div
                    id="viewport"
                    style={{
                      paddingTop: 0,
                    }}
                  >
                    <Outlet />
                    <Footer />
                  </div>
                </AppLayout>
              </Search>
              <ScrollRestoration />
              <div id="portal" style={{ position: "fixed", left: 0, top: 0, zIndex: 9999 }} />
              <script
                // biome-ignore lint/security/noDangerouslySetInnerHtml: required
                dangerouslySetInnerHTML={{
                  __html: `window.ENV = ${JSON.stringify(loadedEnv)}`,
                }}
              />
              <Scripts />
            </SpartaProvider>
          </FingerprintProvider>
        </ApolloProvider>
      </body>
    </html>
  );
}

export default withSentry(App);

/**
 * Boundary for catching Server errors
 *
 * @returns A component that displays the error.
 */
interface IErrorData {
  statusText: string;
  data: string;
}

interface CatchBoundaryProps {
  error: IErrorData; // Error data passed as a prop
}

export function CatchBoundary({ error }: CatchBoundaryProps) {
  useCatchLog(error);

  return <ServerError caught={error} />;
}

/**
 * Boundary for catching errors in React components.
 *
 * @param error - The error that was thrown.
 * @returns A component that displays the error.
 */
export function ErrorBoundary() {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status === 404) {
      navigate("/oops-not-found");
    }
  }, [error]);
  captureRemixErrorBoundaryError(error);
  return <ServerError caught={{ status: 569, data: "Client Failure" }} />;
}
