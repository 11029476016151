import { useLocation, useNavigate } from "@remix-run/react";
import { history } from "instantsearch.js/es/lib/routers";
import { simple } from "instantsearch.js/es/lib/stateMappings";
import { useMemo, useRef } from "react";
import type { PropsWithChildren } from "react";
import { InstantSearch } from "react-instantsearch";
import { searchClient } from "~/sdk/qs3/typesenseClient";
import { searchStateToUrl, urlToSearchState } from "~/utils/search";
import { handleSearchStateChange } from "./searchUtils";

const stateMapping = simple();
const router = history();

export function Search({ children }: PropsWithChildren<{}>) {
  const navigate = useNavigate();
  const location = useLocation();
  // const searchState = useMemo(() => urlToSearchState(location), [location.search]);
  const debouncedSetStateRef = useRef(null);

  /**
   * Every time the search state changes, we update the URL
   * with the new search state. We debounce the URL update
   * to avoid updating the URL too often.
   *
   * @param updatedSearchState The updated ui state from the InstanSearch component
   */
  function onSearchStateChange(updatedSearchState) {
    updatedSearchState.setUiState(updatedSearchState.uiState);
    const newPath = handleSearchStateChange({
      uiState: updatedSearchState.uiState,
      currentPath: location.pathname,
      router,
    });
    if (newPath) {
      navigate(newPath);
    }
  }

  return (
    <InstantSearch searchClient={searchClient} indexName="posts" onStateChange={onSearchStateChange} routing={true}>
      {children}
    </InstantSearch>
  );
}
